.euiBody--headerIsFixed {
  padding-top: 48px;
}

@media (max-width: 991px) {

}

@media (min-width: 992px) {

}
